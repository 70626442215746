/* --------------------------------------------------------------------------
|---------------------------------| TIMERS |--------------------------------
|-------------------------------------------------------------------------- */

import { handleVerifyElementExists } from '../functions/functions-helpers';
import { handleShowModal } from '../functions/functions-modals';

/* --------------------------------------------------------------------------
| ABRE MODAL PARA DELETAR O TIMER...
|-------------------------------------------------------------------------- */
const showModalDeleteTimers = document.querySelectorAll('.button-modal-delete-timers');
handleVerifyElementExists(showModalDeleteTimers, () => {
    showModalDeleteTimers.forEach(element => {
        element.addEventListener('click', (event) => {
            let uri = event.currentTarget.getAttribute('data-uri');
            handleShowModal(event, uri);
        });
    });
});
